/* src/components/masonry-direct.css */

.masonry-container {
  column-count: 1;
  column-gap: 16px;
  width: 100%;
  padding: 0 8px;
}

@media screen and (min-width: 640px) {
  .masonry-container {
    column-count: 2;
  }
}

@media screen and (min-width: 768px) {
  .masonry-container {
    column-count: 3;
  }
}

@media screen and (min-width: 1024px) {
  .masonry-container {
    column-count: 4;
  }
}

.masonry-item {
  display: inline-block;
  width: 100%;
  break-inside: avoid;
  margin-bottom: 16px;
}

.masonry-card {
  display: block;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s;
  background-color: white;
}

.masonry-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.masonry-img {
  width: 100%;
  display: block;
}

.masonry-content {
  padding: 12px;
}
