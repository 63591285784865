/* custom-map.css */

/* Ensure the map container has the correct dimensions */
.custom-map-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.fullscreen-map {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/* Fix for marker pins */
.custom-marker-icon {
  background: transparent;
  border: none;
}

.marker-pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #000;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -15px 0 0 -15px;
}

.marker-pin::after {
  content: '';
  width: 18px;
  height: 18px;
  margin: 6px 0 0 6px;
  background: #fff;
  position: absolute;
  border-radius: 50%;
}

/* Leaflet popup custom styles */
.custom-popup .leaflet-popup-content-wrapper {
  border-radius: 4px;
  padding: 0;
  overflow: hidden;
}

.custom-popup .leaflet-popup-content {
  margin: 0;
  padding: 0;
  width: 250px !important;
}

.custom-popup .leaflet-popup-tip {
  background: white;
}

.event-popup {
  display: flex;
  flex-direction: column;
}

.popup-image-container {
  position: relative;
  height: 120px;
  overflow: hidden;
}

.popup-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.popup-date-tag {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #000;
  color: white;
  font-size: 0.75rem;
  padding: 4px 8px;
}

.popup-category-tag {
  position: absolute;
  top: 0;
  left: 0;
  background: #000;
  color: white;
  font-size: 0.75rem;
  padding: 4px 8px;
  text-transform: uppercase;
}

.popup-content {
  padding: 12px;
}

.popup-title {
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 4px;
}

.popup-date {
  color: #4b5563;
  font-size: 0.85rem;
  margin-bottom: 8px;
}

.popup-location {
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  color: #4b5563;
  margin-bottom: 8px;
}

.popup-icon {
  width: 14px;
  height: 14px;
  margin-right: 4px;
}

.popup-link {
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  font-weight: 500;
  color: #000;
  text-decoration: none;
}

.popup-arrow {
  width: 14px;
  height: 14px;
  margin-left: 4px;
  transition: transform 0.2s;
}

.popup-link:hover .popup-arrow {
  transform: translateX(3px);
}

/* Fix for leaflet tile rendering */
.leaflet-layer,
.leaflet-control,
.leaflet-pane {
  z-index: 1 !important;
}

.leaflet-pane {
  z-index: 1 !important;
}

.leaflet-tile-pane {
  z-index: 1 !important;
}

.leaflet-overlay-pane {
  z-index: 2 !important;
}

.leaflet-marker-pane {
  z-index: 3 !important;
}

.leaflet-popup-pane {
  z-index: 4 !important;
}

.leaflet-tooltip-pane {
  z-index: 5 !important;
}

.leaflet-control {
  z-index: 6 !important;
}
